import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TwoFactorAuth from '../views/TwoFactorAuth.vue'
//import { store } from './store/index';

Vue.use(VueRouter)

const guest = (to, from, next) => {
  if (!localStorage.getItem("token")) {
    return next();
  } else {
    return next("/");
  }
};

const auth = (to, from, next) => {
  if (localStorage.getItem("token")) {
    // Si no es la primera vez, continuar con la navegación normal
    return next();
  } else {
    return next("/auth/login");
  }
};

/* const guest = (to, from, next) => {
  if (!store.getters['user/autenticado']) {
    return next();
  } else {
    return next("/");
  }
};

const auth = (to, from, next) => {
  if (store.getters['user/autenticado']) {
    const isTwoFactorAuthComplete = store.getters['user/isTwoFactorAuthComplete'];
    if (isTwoFactorAuthComplete || to.name === 'TwoFactorAuth') {
      return next();
    } else {
      return next('/auth/two-factor-auth');
    }
  } else {
    return next("/auth/login");
  }
}; */

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'TRONMine | Home',
      description: 'Welcome to TronMine - Driving TRON mining with the fastest growth in the market.'
    },
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: 'TRONMine | About',
      description: 'Welcome to TronMine - Driving TRON mining with the fastest growth in the market.'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/faqs',
    name: 'faqs',
    meta: {
      title: 'TRONMine | FAQ',
      description: 'Welcome to TronMine - Driving TRON mining with the fastest growth in the market.'
    },
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
  },
  {
    path: '/partners',
    name: 'partners',
    meta: {
      title: 'TRONMine | Affiliate',
      description: 'Welcome to TronMine - Driving TRON mining with the fastest growth in the market.'
    },
    component: () => import(/* webpackChunkName: "partners" */ '../views/Affiliate.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: 'TRONMine | Contact Us',
      description: 'Welcome to TronMine - Driving TRON mining with the fastest growth in the market.'
    },
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/auth/login',
    name: 'login',
    beforeEnter: guest,
    meta: {
      title: 'TRONMine | Login',
      description: 'Welcome to TronMine - Driving TRON mining with the fastest growth in the market.'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/auth/register',
    name: 'register',
    beforeEnter: guest,
    meta: {
      title: 'TRONMine | Register',
      description: 'Welcome to TronMine - Driving TRON mining with the fastest growth in the market.'
    },
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/auth/two-factor-auth',
    name: 'TwoFactorAuth',
    component: TwoFactorAuth,
    meta: {
      title: 'TRONMine | Login',
      description: 'Enable two-factor authentication (2FA) for added security. Protect your account from unauthorized access.'
    },
  },
  {
    path: '/auth/affiliate',
    name: 'referral',
    beforeEnter: guest,
    meta: {
      title: 'TRONMine | Register',
      description: 'Welcome to TronMine - Driving TRON mining with the fastest growth in the market.'
    },
    component: () => import(/* webpackChunkName: "registerref" */ '../views/ReferralRegister.vue')
  },
  {
    path: '/members/mining',
    name: 'Dashboard',
    beforeEnter: auth,
    meta: {
      title: 'TRONMine | Mining',
      description: 'Access your TRONMine dashboard to manage and monitor your mining activities efficiently.',
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Members/Dashboard.vue')
  },
  {
    path: '/members/deposit',
    name: 'Deposits',
    beforeEnter: auth,
    meta: {
      title: 'TRONMine | Deposits',
      description: 'Access your TRONMine dashboard to manage and monitor your mining activities efficiently.',
    },
    component: () => import(/* webpackChunkName: "deposits" */ '../views/Members/Deposits.vue')
  },
  {
    path: '/members/withdraw',
    name: 'Withdraws',
    beforeEnter: auth,
    meta: {
      title: 'TRONMine | Withdraw',
      description: 'Access your TRONMine dashboard to manage and monitor your mining activities efficiently.',
    },
    component: () => import(/* webpackChunkName: "withdraw" */ '../views/Members/Withdraws.vue')
  },
  {
    path: '/members/account',
    name: 'Account',
    beforeEnter: auth,
    meta: {
      title: 'TRONMine | Account',
      description: 'Access your TRONMine dashboard to manage and monitor your mining activities efficiently.',
    },
    component: () => import(/* webpackChunkName: "account" */ '../views/Members/Account.vue')
  },
  {
    path: '/members/settings',
    name: 'Settings',
    beforeEnter: auth,
    meta: {
      title: 'TRONMine | Settings',
      description: 'Access your TRONMine dashboard to manage and monitor your mining activities efficiently.',
    },
    component: () => import(/* webpackChunkName: "settings" */ '../views/Members/Settings.vue')
  },
  {
    path: '/members/affiliates',
    name: 'Affiliates',
    beforeEnter: auth,
    meta: {
      title: 'TRONMine | Affiliates',
      description: 'Access your TRONMine dashboard to manage and monitor your mining activities efficiently.',
    },
    component: () => import(/* webpackChunkName: "partners" */ '../views/Members/Affiliates.vue')
  },
  {
    path: '/members/bonus',
    name: 'Bonus',
    beforeEnter: auth,
    meta: {
      title: 'TRONMine | Bonus',
      description: 'Access your TRONMine dashboard to manage and monitor your mining activities efficiently.',
    },
    component: () => import(/* webpackChunkName: "bonus" */ '../views/Members/Bonus.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.description) {
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description);
  }

  // Cambia la clase del body
  if (to.meta.bodyClass) {
    document.body.className = to.meta.bodyClass;
  } else {
    document.body.className = '';
  }

  next();
});

export default router
