import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/index';
import FloatingVue from 'floating-vue';
import VueParticles from 'vue-particles'
import axios from 'axios'
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import VueMq from 'vue-mq';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';



Vue.use(VueParticles)
Vue.use(FloatingVue);
Vue.use(VueSweetalert2);
Vue.use(VueMq, {
  breakpoints: { // define los breakpoints según tus necesidades
    sm: 450,
    md: 1250,
    lg: Infinity
  }
});
require('./store/subscriber');
import './assets/css/main.css';
import './assets/css/app.min.css';
import './assets/css/iconfont.css';
import './assets/css/responsive.css';
import 'floating-vue/dist/style.css';


Vue.config.productionTip = false

// Obtener el token JWT del almacenamiento local o de alguna otra fuente
const jwtToken = localStorage.getItem('token');

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  encrypted: true,
  authEndpoint: 'https://backend.tronmine.cloud/broadcasting/auth',
  auth: {
    headers: {
      Authorization: 'Bearer ' + jwtToken
    },
  },
});

let app = '';
store.dispatch('user/intento', localStorage.getItem('token')).then(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  }
});