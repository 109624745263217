import axios from 'axios';

export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        balance: 0,
        power: 0,
    },
    mutations: {
        EL_TOKEN(state, token) {
            state.token = token
        },
        EL_USUARIO(state, data) {
            state.user = data
        },
        setBalance(state, newBalance) {
            state.balance = newBalance;
        },
        setPower(state, newPower) {
            state.power = newPower;
        }
    },
    getters: {
        autenticado(state) {
            return state.token && state.user
        },
        usuario(state) {
            return state.user
        },
        balance(state) {
            return state.balance
        },
        power(state) {
            return state.power
        }
    },
    actions: {
        async fetchSaldo({ commit }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'getUserBalance');
                const newBalance = parseFloat(response.data.balance); // Ajusta según la estructura de tu respuesta
                const newPower = response.data.power;
                commit('setBalance', newBalance);
                commit('setPower', newPower);
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        },
        async signIn({ dispatch }, credenciales) {
            let res = await axios.post(process.env.VUE_APP_API_URL + 'login', credenciales)
            // Verificar si el usuario tiene habilitado el 2FA
            if (res.data.twoFactorEnabled) {
                // Si tiene 2FA habilitado, redirigir a la página de verificación de 2FA
                // o mostrar el formulario de 2FA aquí
                // Aquí asumimos que tienes una ruta llamada 'verify-2fa' para la verificación de 2FA
                await dispatch('intento', res.data.token);
                return { twoFactorEnabled: true };
            } else {
                // Si no tiene 2FA habilitado, continuar con la acción normal de inicio de sesión
                await dispatch('intento', res.data.token);
                return { twoFactorEnabled: false };
            }
        },
        async intento({ commit, state }, token) {
            if (token) {
                commit('EL_TOKEN', token)
            }

            if (!state.token) {
                return
            }

            try {
                let res = await axios.get(process.env.VUE_APP_API_URL + 'me')
                commit('EL_USUARIO', res.data)
            } catch (e) {
                commit('EL_USUARIO', null)
                commit('EL_TOKEN', null)
            }
        },
        async logout(context) {
            let res = await axios.post(process.env.VUE_APP_API_URL + 'logout');
            localStorage.removeItem('token');
            context.commit('EL_USUARIO', null);
            context.commit('EL_TOKEN', null);
            return res.data
        },
    }
}