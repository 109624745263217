<template>
  <div class="currentTRXInfo trxgroup-wave" :class="subebtc ? 'up' : 'down'">
    <span class="TRXPrice">
        <span class="nav-price-wrapper">
        <span class="trxTitle">TRX:</span>
        <span class="currentTrxPirceNoTimeout">
            <span class="normalCurrentTrxPirce">
                <span class="currentTrxPirce showPirce">
                <span>{{trxPrice}}</span>
                </span>
                <span class="currentCurrency currentCurrencyMobile"> USD </span>
                <span class="arrow-img">
                    <i class="iconfont tron-font-size-10px" :class="subebtc ? 'icon-up' : 'icon-down'"></i>
                </span>
            </span>
        </span>
        </span>
    </span>
</div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'TrxPrice',

  data() {
    return {
        trxPrice: 0,
        previousPrice: 0,
        interval: null,
        subebtc: true,
        palpitar: false,
    };
  },

  mounted() {
    this.fetchTrxPrice()
  },

  methods: {
    fetchTrxPrice() {
        axios.get(process.env.VUE_APP_API_URL + 'ticker-prices')
        .then((res)=>{
            this.previousPrice = this.trxPrice;
            this.trxPrice = res.data.trxPrice;
            this.checkPriceChange();
        })
        .catch((error)=>{
            console.error("Error fetching trx prices:", error);
        });
    },
    checkPriceChange() {
        const currentPrice = this.trxPrice;
        const lastPrice = this.previousPrice;

        if (currentPrice > lastPrice) {
            this.subebtc = true;
        } else if (currentPrice < lastPrice) {
            this.subebtc = false;
            this.palpitar = true;
        }
    }
  },
};
</script>
<style scoped>
.currentTRXInfo {
    font-size: 13px; /* Ajusta el tamaño de la fuente del precio */
    font-weight: 700;
    color: #333; /* Color del texto del precio */
}

.TRXPrice .nav-price-wrapper .trxTitle,
.TRXPrice .nav-price-wrapper .currentTrxPirce,
.TRXPrice .nav-price-wrapper .currentCurrency {
    display: inline;
}

.currentCurrencyMobile {
    display: inline; /* Asegúrate de que la moneda esté en línea */
}

.trxgroup-wave {
    border-radius: 5px;
    display: inline;
    font-size: 12px;
    height: 19px;
    line-height: 19px;
    margin-left: 8px;
    padding: 0 6px;
}

.trxgroup-wave.up {
    background-color: #ebf9eb;
    color: #2d912c;
}

.trxgroup-wave .arrow-img {
    align-items: center;
    display: inline-flex;
    width: 10px;
}

.tron-font-size-10px {
    font-size: 12px;
    transform: scale(.83);
}

.trxgroup-wave.down {
    background-color: rgba(194,54,49,.1);
    color: #c23631;
}

.palpitar {
    animation: palpitar 2s infinite;
}

@keyframes palpitar {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}
</style>