<template>
  <div>
    <Header/>
    
    <div class="jsx-4083713349 banner-container">

    <div id="banner-container" class="jsx-1114352951 banner">
      
      <div style="position:relative" class="jsx-1114352951">
        <div style="width:100%;color:#fff;overflow:hidden" class="jsx-1114352951 banner-box">
          
          <div class="jsx-1114352951 banner-bg banner-en-bg1">
            <div class="jsx-1114352951 banner-tron">
              <img src="@/assets/images/TR.png" class="jsx-1114352951 banner-tr">
              <img src="@/assets/images/ON.png" class="jsx-1114352951 banner-on">
              <vue-particles color="#dedede" shapeType="polygon" :moveSpeed="16"></vue-particles>
            </div>
              
            <div class="jsx-1114352951 banner-desc">MINE CLOUD</div>
            <div class="jsx-1114352951 maindata-desc">  Join over 237 million accounts and experience the future of cloud mining with TRON MINE CLOUD, the fastest-growing TRON mining platform.</div>
            
            <div style="position:absolute;left:0;top:-20px;z-index:10" class="jsx-1114352951 webtrx-canvas">
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="jsx-1556996510 main-data">
      <div class="jsx-1556996510 data-content">
        <div class="jsx-1556996510 item">
          <p class="jsx-1556996510 number">
            <span>{{users}}</span>
          </p>
          <p class="jsx-1556996510 title">Total Number of Accounts</p>
        </div>
        <div class="jsx-1556996510 item">
          <p class="jsx-1556996510 number">
            <span>{{deposits}}</span>
          </p>
          <p class="jsx-1556996510 title">Total TRON Deposits</p>
        </div>
        <div class="jsx-1556996510 item">
          <p class="jsx-1556996510 number">
            <span>{{withdrawals}}</span>
          </p>
          <p class="jsx-1556996510 title">Total TRON Withdrawals</p>
        </div>
        <div class="jsx-1556996510 item">
          <p class="jsx-1556996510 number">
            <span>{{ days }}</span>
          </p>
          <p class="jsx-1556996510 title">Working Days</p>
        </div>
      </div>
    </div>

  </div>


  <div class="jsx-3751484287 Chart">
    <div class="jsx-3751484287 swiper-container swiper-container-initialized swiper-container-horizontal">
  <div class="container-xl px-4 mt-4">
    <div class="jsx-1406879810 trx-market">
      <h3 class="jsx-1406879810">Recent Transactions</h3>
      <div class="table-wrap relative shadow">
                              <div class="table-responsive table-container">
                                  <table class="table-striped nh padding-normal" v-if="transactions.length > 0">
                                      <thead>
                                          <tr>
                                              <th scope="col">Time</th>
                                              <th scope="col">TXid</th>
                                              <th scope="col" class="text-right">Amount</th>
                                              <th scope="col" class="text-right">Type</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(transaction, index) in transactions" :key="index">
                                            <td>{{ formatDate(transaction.created_at) }}</td>
                                              <td >
                                                <a :href="'https://tronscan.org/#/transaction/' + transaction.transaction_id" target="_blank">
                                                      {{ transaction.cutTxid }}
                                                  </a>
                                              </td>
                                              <td class="text-right">{{ transaction.amount }}</td>
                                              <!-- <td class="text-right">{{ transaction.username }}</td> -->
                                              <td class="text-right">
                                                <template v-if="transaction.type == 1">
                                                  <span class="badge bg-success-soft text-success">Deposit</span>
                                                </template>
                                                <template v-else>
                                                  <span class="badge bg-success-soft text-info">Withdraw</span>
                                                </template>
                                              </td>
                                              
                                          </tr>
                                      </tbody>
                                  </table>

                                  <div v-else-if="!isLoading && transactions.length == 0" class="panel d-flex justify-content-center mt-4">
                                      <p>No Data</p>
                                  </div>

                                  <div class="overlay loader" v-if="isLoading">
                                    <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                                  </div>

                              </div>
                            </div>
            </div>
        </div>
      </div>
    </div>

    <section>
    <div class="container px-5">
      <div class="row gx-5 justify-content-center">
        <div class="col-lg-6">
          <div class="text-center">
            <div class="text-xs text-uppercase-expanded text-primary mb-2">1 GH/s for every $0.025</div>
            <h2 class="mb-5">Profit Calculator</h2>
          </div>
        </div>
      </div>
      <div class="row gx-5 justify-content-center">
        <div class="col-lg-10">
          <div class="card mb-4">
            <div class="card-body">
              <div class="row gx-3 mb-3">
                <div class="col-md-6 mb-3">
                  <div class="form-floating">
                    <input class="form-control text-gray-900" v-model="trxAmount" id="inputDepositAmount" type="tel" placeholder="Coin amount to invest..." readonly>
                    <label class="text-gray-600" for="inputDepositAmount">Coins amount to invest</label>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-floating">
                    <input class="form-control text-gray-900" v-model="usdAmount" @input="calculateFromUsd" id="inputDepositUsd" type="tel" placeholder="USD amount to invest...">
                    <label class="text-gray-600" for="inputDepositUsd">USD amount to invest</label>
                    <small v-if="usdAmount < 5 && usdAmount !== ''" class="text-danger">The minimum amount in USD is 5.</small>
                  </div>
                </div>
              </div>
              <div class="row gx-3">
                <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
                  <div class="d-flex flex-column flex-grow-1 flex-lg-row justify-content-between mt-3 mt-md-0 justify-content-lg-start">
                    <div class="me-5">
                      <div class="d-flex align-items-center my-2 mx-sm-0">
                        <i class="fas fa-2x fa-bolt text-warning" style="width: 40px;"></i>
                        <div class="ms-2">
                          <div class="text-small">{{ ghPower }} GH/s</div>
                          <div class="text-xs text-muted">Power</div>
                        </div>
                      </div>
                    </div>
                    <div class="me-5">
                      <div class="d-flex align-items-center my-2 mx-sm-0">
                        <i class="fas fa-2x fa-chart-line text-warning" style="width: 40px;"></i>
                        <div class="ms-2">
                          <div class="text-small">{{ profitPercentage }} %/day</div>
                          <div class="text-xs text-muted">Plan</div>
                        </div>
                      </div>
                    </div>
                    <div class="me-5">
                      <div class="d-flex align-items-center my-2 mx-sm-0">
                        <i class="fas fa-2x fa-dollar-sign text-warning" style="width: 40px;"></i>
                        <div class="ms-2">
                          <div class="text-small">{{ dailyProfit.toFixed(2) }} USD</div>
                          <div class="text-xs text-muted">Profit</div>
                        </div>
                        <select class="ms-3 form-control form-control-sm" style="max-width: 100px;" v-model="selected" @change="calculateProfit">
                          <option value="1">per 1 day</option>
                          <option value="10">per 10 days</option>
                          <option value="30">per 30 days</option>
                          <option value="60">per 60 days</option>
                          <option value="180">per 180 days</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <router-link class="btn btn-primary btn-tron fw-semibold text-uppercase mt-5 mt-md-0" to="/auth/register">Register to deposit</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


    <section class="pt-10">
            <div class="container px-5">
              <div class="text-center mb-5">
                <h2>Best choice</h2>
                <p class="lead">The most popular solutions</p>
              </div>
              <div class="row gx-5 z-1">
                <div class="col-lg-4 mb-5 mb-lg-n10">
                  <div class="card pricing h-100">
                    <div class="card-body p-5">
                      <div class="text-center">
                        <div class="badge bg-light text-dark rounded-pill badge-marketing badge-sm">Standard</div>
                        <div class="pricing-price "> 2.5% <span class="pricing-price-period">/day</span>
                        </div>
                      </div>
                      <ul class="fa-ul pricing-list">
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>2.5% per day</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>160 GH/s bonus</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>Investment period 180 days</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>Online support</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>$5 or 200 GH/s</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 mb-5 mb-lg-n10">
                  <div class="card pricing h-100">
                    <div class="card-body p-5">
                      <div class="text-center">
                        <div class="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">Premium
                        </div>
                        <div class="pricing-price "> 4% <span class="pricing-price-period">/day</span>
                        </div>
                      </div>
                      <ul class="fa-ul pricing-list">
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>4% per day</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>160 GH/s bonus</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>Investment period 180 days</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>Online support</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>$250 or 10000 GH/s</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 mb-5 mb-lg-n10">
                  <div class="card  pricing h-100">
                    <div class="card-body p-5">
                      <div class="text-center">
                        <div class="badge bg-danger-soft rounded-pill badge-marketing badge-sm text-danger">Investor</div>
                        <div class="pricing-price "> 5% <span class="pricing-price-period">/day</span>
                        </div>
                      </div>
                      <ul class="fa-ul pricing-list">
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>5% per day</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>160 GH/s bonus</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>Investment period 180 days</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>24/7 telegram support</span>
                        </li>
                        <li class="pricing-list-item">
                          <span class="fa-li">
                            <i class="far fa-check-circle text-teal"></i>
                          </span>
                          <span>$1500 or 60000 GH/s</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        
          <TronPriceChart />
        
    

  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import axios from 'axios'
import Header from '@/components/Header.vue'
import TronPriceChart from '@/components/TronPriceChart.vue'
export default {
  name: 'HomeView',
  components: {
    Header, TronPriceChart
  },
  data: function() {
    return {
      users: 0,
      withdrawals:0,
      deposits: 0,
      days:0,
      isLoading: true,
      interval: null,
      firstLoad: true,
      transactions: [],
      trxAmount: 0,
      usdAmount: 5, // Inicializa usdAmount en 5
      ghPower: 0,
      dailyProfit: 0,
      selected: 1,
      trxPrice: 0, // Valor de TRX obtenido del ticker
      profitPercentage: 2.5 // Inicializa con el porcentaje más bajo
    };
  },
  watch: {
    /* trxAmount() {
      this.usdAmount = this.trxAmount * this.trxPrice;
      this.calculateGhPower();
      this.calculateProfit();
    },
    usdAmount() {
      this.calculateProfit();
    } */
  },
  mounted() {
    this.fetchTrxPrice();
    this.calculateProfit();
    this.getIndexData();
  },
  beforeMount() {
    this.interval = setInterval(this.getIndexData, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getIndexData() {
      axios.get(process.env.VUE_APP_API_URL + 'getTransactionData')
      .then((res)=>{
        this.users = res.data.userCount;
        this.withdrawals = res.data.totalWithdrawals;
        this.deposits = res.data.deposits;
        this.days = res.data.workingdays; 
        this.transactions = res.data.lastTransactions;
        if (this.firstLoad) {
            this.firstLoad = false; // Desactiva la bandera después de la primera carga
        }
        
      })
      .catch((error)=>{
        console.log('error getting insightsData',error.response.error);
      })
      .finally(() => {
          this.isLoading = false;
      });
    },
    fetchTrxPrice() {
        axios.get(process.env.VUE_APP_API_URL + 'ticker-prices')
        .then((res)=>{
            this.trxPrice = res.data.trxPrice;
            this.calculateFromUsd();
        })
        .catch((error)=>{
            console.error("Error fetching trx prices:", error);
        });
    },
    calculateFromUsd() {
      /* if (this.usdAmount < 5) {
        this.usdAmount = 5;
      } */
      this.trxAmount = this.usdAmount / this.trxPrice;
      this.calculateGhPower();
      this.calculateProfit();
    },
    formatDate(date) {
        return moment(date).format('DD MMM YYYY HH:mm');
    },
    calculateGhPower() {
      this.ghPower = this.usdAmount / 0.025;
    },
    calculateProfitPercentage() {
      if (this.usdAmount <= 100) {
        this.profitPercentage = 2.5;
      } else if (this.usdAmount <= 250) {
        this.profitPercentage = 3;
      } else if (this.usdAmount <= 750) {
        this.profitPercentage = 4;
      } else if (this.usdAmount <= 1499) {
        this.profitPercentage = 4.5;
      } else {
        this.profitPercentage = 5;
      }
    },
    calculateProfit() {
      this.calculateProfitPercentage();
      this.dailyProfit = (this.usdAmount * (this.profitPercentage / 100)) * this.selected;
    }
  }
}
</script>
<style scoped>
#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: transparent;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px){
.container {
    width: 750px;
}
}

@media (min-width: 992px){
.container {
    width: 970px;
}
}

@media (min-width: 1200px){
.container {
    width: 1170px;
}
}

@media (min-width: 576px){
.container, .container-sm {
    width: 100%;
}
}

.ml-3 {
  margin-left:15px
}

.br-table {
  border-radius: 0.375rem;
}

.mb-2 {
  margin-bottom: 3px
}

.py-2 {
    padding-top: 0.375rem!important;
}

.feature-box {
  background-color: var(--bs-body-bg);
  border-radius: 4px;
  flex: 1 1 auto;
    padding: 1rem 1rem;
    color: var(--bs-card-color);
}

.active {
    background-color: #FF4D6B;
    color: white;
}

.table {
    box-shadow: 0 1px 4px #23232329;
    margin: 0 auto 80px;
}

.table-container {
    position: relative;
    min-height: 90px;
    overflow-x: auto;
}

table.nh {
    width: 100%;
    border: none;
}

table.nh thead {
    background: #fff;
}

table.nh th:first-child {
    padding-left: 32px;
}

.table th {
    color: #959595;
}

table.nh th {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
    padding: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

table.nh tbody tr:nth-child(odd) {
    background-color: #fcfcfc;
}

table.nh tbody tr:first-child {
    box-shadow: inset 1px 4px 6px -5px #23232373;
}

table.nh tbody tr {
    background-color: #fff;
}

table.nh tbody tr td:first-child {
    padding-left: 32px;
}

table.nh tbody tr td {
    padding: 12px 16px;
    border-bottom: 1px solid #EEE;
    font-size: 12px;
    line-height: 1.33;
    color: #3a3939;
}

.text-muted {
    color: #959595 !important;
}

.text-right {
    text-align: right;
}

.wallet-container {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 32px;
}

.sidebar {
    width: 232px;
}

.box {
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
    margin-bottom: 16px;
    background: #fff;
}

.box .total {
    padding: 24px 16px 0;
    border-bottom: 1px solid #EEE;
    position: relative;
}

.box .available {
    padding: 24px 16px 12px;
    position: relative;
}

.box i.info {
    position: absolute;
    top: 12px;
    right: 12px;
}

.box .total h1 {
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.box .available h1 {
  font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.pb16 {
    padding-bottom: 16px;
}

.pt8 {
    padding-top: 8px;
}

.pointer {
    cursor: pointer;
}

.text-primary {
    color: rgb(25, 52, 93) !important;
}

.content {
    flex: 1;
    margin-left: 0px;
}

.box .pending {
    padding: 24px 16px 18px;
    position: relative;
}

.text-bold {
    font-weight: 700;
}

.fs16 {
    font-size: 16px;
}

.mb8 {
    margin-bottom: 8px;
}

.mt8 {
    margin-top: 8px;
}

.box .available .deposit-withdraw {
    margin: 16px 0;
}

.box .available .deposit-withdraw .btn {
    padding: 6px!important;
    width: calc(50% - 4px);
    margin: 0;
}

.mb16 {
    margin-bottom: 18px;
}

.content {
    box-shadow: rgba(35, 35, 35, 0.16) 0px 1px 4px;
    background: none !important;
    flex: 1 1 0%;
    border-radius: 4px;
}

.fs16 {
  font-size: 16px;
}

.skeleton-balance .line {
     height: 12px;
     margin-top: 6px;
     margin-bottom: 6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

.skeleton-left {
    flex:1;
}

.skeleton-balance {
     padding:15px 15px 15px 0;
     max-width: 300px;
     width: 135px;
     height: 10px;
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
}

.text-small {
  font-size: 16px;
  font-weight: 700;
}

</style>