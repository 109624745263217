<template>
  <div>
    <div class="jsx-1371797455 resources-box">
          <div id="banner-container" class="jsx-2999059416 jsx-4177316471 banner">
            <div class="jsx-2999059416 jsx-4177316471" style="position: relative;">
              <div class="jsx-2999059416 jsx-4177316471 banner-bg banner-en-bg1"></div>
            </div>
          </div>

          <div class="container jsx-1371797455 resource-content mt-10">
            <div class="row justify-content-center">
              <div class="col-md-6">
                <div class="text-center dark mb-5">
                    <h2 class="tron-h2 fw-bold mb-2">Login</h2>
                </div>
                <div class="card bg-white shadow-lg border-0" >
                  <div class="card-body">
                    <form @submit.prevent="verifyTwoFactorCode">

                      <div class="field-wrap input- mb24">
                        <div class="input-group2 auth">
                          <div class="input-group-prepend">
                            <i class="fa fa-user"></i>
                          </div>
                          <input autocomplete="off" type="text" @focus="isCodeFocused = true" @blur="isCodeFocused = false" name="code" :class="{ 'up': isCodeFocused || verificationCode  }" class="form-control" v-model="verificationCode" placeholder="" maxlength="" required>
                          <span class="floating-placeholder prepend" :class="{ 'up': isCodeFocused || verificationCode  }">Verification Code</span>
                        </div>
                      </div>

                      <div class="col-12 mt-3 d-grid gap-2 d-md-flex justify-content-md-end mt-3">
                        <a href="javascript:void(0)" @click="onLogOut" class="link fw-bold text-decoration-none text-tron">Cancel</a>
                      </div>
                      <div class="col-12">
                        <button type="submit" class="btn w-100 text-white btn-primary btn-tron rounded-3 py-3 fw-semibold text-uppercase mt-2" :disabled="isSubmitting">
                            <span v-if="!isSubmitting">Login</span>
                            <span v-else>
                                <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                                Loading...
                            </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'TronmineTwoFactorAuth',

  data() {
    return {
        isSubmitting: false,
        verificationCode: '',
        isCodeFocused: false,
    };
  },

  mounted() {
    
  },

  methods: {
    ...mapActions({
        logout: 'user/logout'
    }),
    verifyTwoFactorCode() {
        if (this.isSubmitting) return;

        // Inicia la animación del botón y deshabilítalo
        this.isSubmitting = true;
        
        axios.post(process.env.VUE_APP_API_URL + 'verifyTwoFactor', {
            code: this.verificationCode
        })
        .then(response => {
          // Limpia los campos después de enviar
          this.verificationCode = '';
          // Si la solicitud fue exitosa, puedes redirigir al usuario a su dashboard
          this.$router.push({ name: "Dashboard" });
        })
        .catch((error)=>{
            if (error.response.status === 422) {
                this.$swal({
                    toast: true,
                    icon: "error",
                    title: error.response.data.error,
                    showConfirmButton: false,
                    timer: 2500
            });
            this.isSubmitting = false;
                
            }
        })
    },
    onLogOut() {
        this.logout().then((res) => {
            this.$router.push({ name: "login" });
        })
    },
  },
};
</script>
<style scoped>
.mb24 {
    margin-bottom: 24px;
}

.input-group2 {
    position: relative;
}

.input-group2.auth .input-group-prepend, .input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px;
}

.input-group2 .input-group-prepend {
    position: absolute;
    top: 3px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group2.auth .input-group-prepend~input, .input-group.medium .input-group-prepend~input {
    padding-left: 56px;
}

.input-group2.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group2.auth span.floating-placeholder, .input-group.medium span.floating-placeholder {
    position: absolute;
    pointer-events: none;
    left: 19px;
    top: 19px;
    transition: .2s ease all;
    color: #cecdcd;
    font-size: 14px;
}

.input-group2.auth span.floating-placeholder.prepend,.input-group.medium span.floating-placeholder.prepend {
    left: 56px!important
}

.input-group2.auth span.floating-placeholder.up,.input-group.medium span.floating-placeholder.up {
    top: 11px;
    left: 15px;
    font-size: 10px;
    color: #959595
}

.input-group2.auth input.up,.input-group.medium input.up {
    padding-top: 25px;
    padding-bottom: 11px
}

.input-group2 input:focus {
    outline: none;
    border: 1px solid #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

.input-group2.auth .show-password, .input-group.medium .show-password {
    line-height: 54px;
}

.input-group2.auth .show-password {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    z-index: 1;
    color: rgb(92, 14, 14);
    cursor: pointer;
}

.input-group2.auth .show-password span {
    margin-right: 7px;
    font-size: 12px;
}

.input-group2 .show-password i {
    display: inline-block;
}

.input-group2 .show-password.show {
    color: #cecdcd;
    right: 20px
}

.mt-10{
  padding-top: 100px;
}

.btn-primary{
  background: rgb(92, 14, 14);
  border-color: rgb(92, 14, 14);
}
</style>