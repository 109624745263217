<template>
  <div>

      <div class="page-wrapper null compact-wrapper" id="pageWrapper" v-if="isSpecificRoute">
    


    
        <div class="page-header row" :class="{ 'close_icon': isSideBarOpen }">
          <div class="header-logo-wrapper col-auto">
            <div class="logo-wrapper"><router-link to="/"><img class="img-fluid for-light" src="@/assets/images/tronmine_en.png" alt=""><img class="img-fluid for-dark" src="@/assets/images/tronmine_en.png" alt=""></router-link></div>
          </div>
          <!-- Page Header Start-->
              <div class="header-wrapper col m-0">
              <div class="row">
                  <div class="header-logo-wrapper col-auto p-0">
                  <div class="logo-wrapper"><router-link to="/">
                      <img class="img-fluid" src="@/assets/images/tronmine_en.png" alt=""></router-link>
                  </div>
                  <div class="toggle-sidebar" @click="toggleSideBar">
                  <svg class="stroke-icon sidebar-toggle status_toggle middle">
                    <use href="@/assets/svg/icon-sprite.svg#toggle-icon"></use>
                  </svg>
                </div>
                  </div>
                  <div class="nav-right col-xxl-8 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
                      <ul class="nav-menus">
                      <li>
                      <div class="mode" @click="toggleMode">
                          <svg>
                          <use href="@/assets/svg/icon-sprite.svg#moon"></use>
                          </svg>
                      </div>
                      </li>
                      <li class="profile-nav onhover-dropdown px-0 py-0">
                      <div class="d-flex profile-media align-items-center" v-click-outside="hide" @click="toggleProfile">
                          <img class="img-30" src="@/assets/images/trx.png" alt="">
                          <div class="flex-grow-1"><span>{{user ? user.user.username : ''}}</span>
                          <p class="mb-0 font-outfit">User<i class="fa fa-angle-down"></i></p>
                          </div>
                      </div>
                      <ul class="profile-dropdown onhover-show-div" :class="{'active': isProfileOpen}">
                          <li><router-link to="/members/account"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg><span>Account </span></router-link></li>
                          <li><router-link to="/members/settings"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg><span>Settings</span></router-link></li>
                          <li><a href="javascript:void(0)" @click="onLogOut"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg><span>Log out</span></a></li>
                      </ul>
                      </li>
                  </ul>
                  </div>
              </div>
              </div>
          <!-- Page Header Ends                              -->
        </div>

        <div class="page-body-wrapper">

          <div class="sidebar-wrapper" :class="{ 'close_icon': isSideBarOpen }" data-layout="stroke-svg">
            <div>
              <div class="logo-wrapper">
                <router-link to="/">
                  <img class="img-fluid" src="@/assets/images/tronmine_en.png" alt="">
                </router-link>
                <div class="back-btn" @click="toggleSideBar">
                  <i class="fa fa-angle-left"></i>
                </div>
                <div class="toggle-sidebar"  checked="checked" >
                  <svg class="stroke-icon sidebar-toggle status_toggle middle">
                    <use href="@/assets/svg/icon-sprite.svg#toggle-icon"></use>
                  </svg>
                  <svg class="fill-icon sidebar-toggle status_toggle middle">
                    <use href="@/assets/svg/icon-sprite.svg#fill-toggle-icon"></use>
                  </svg>
                </div>
              </div>
              <div class="logo-icon-wrapper">
                <router-link to="/">
                  <img class="img-fluid" src="@/assets/images/trx.png" alt="">
                </router-link>
              </div>
              <nav class="sidebar-main">
                <div class="left-arrow disabled" id="left-arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left">
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline points="12 19 5 12 12 5"></polyline>
                  </svg>
                </div>
                <div id="sidebar-menu">
                  <ul class="sidebar-links" id="simple-bar" data-simplebar="init" style="display: block;">
                    <div class="simplebar-wrapper" style="margin: 0px;">
                      <div class="simplebar-mask" >
                        <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                          <div class="simplebar-content-wrapper" :style="isSideBarOpen ? 'height: 100%; overflow: hidden scroll;' : 'height: auto; overflow: hidden;'">
                            <div class="simplebar-content" style="padding: 0px;">
                              <li class="back-btn" @click="toggleSideBar" >
                                <div class="mobile-back text-end">
                                  <span>Back</span>
                                  <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                                </div>
                              </li>
                              <li class="sidebar-list">
                                <router-link class="sidebar-link sidebar-title" to="/members/mining">
                                  <svg class="stroke-icon">
                                    <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
                                  </svg>
                                  <svg class="fill-icon">
                                    <use href="@/assets/svg/icon-sprite.svg#fill-home"></use>
                                  </svg>
                                  <span class="lan-3">Mining</span>
                                  
                                </router-link>
                              </li>
                              <li class="sidebar-list">
                                  <router-link class="sidebar-link sidebar-title link-nav" to="/members/deposit">
                                    <svg class="stroke-icon">
                                      <use href="@/assets/svg/icon-sprite.svg#stroke-board"></use>
                                    </svg>
                                    <svg class="fill-icon">
                                      <use href="@/assets/svg/icon-sprite.svg#fill-board"></use>
                                    </svg><span>Deposit</span>
                                  </router-link>
                              </li>
                              <li class="sidebar-list">
                                  <router-link class="sidebar-link sidebar-title link-nav" to="/members/withdraw">
                                    <svg class="stroke-icon">
                                      <use href="@/assets/svg/icon-sprite.svg#stroke-board"></use>
                                    </svg>
                                    <svg class="fill-icon">
                                      <use href="@/assets/svg/icon-sprite.svg#fill-board"></use>
                                    </svg><span>Withdraw</span>
                                  </router-link>
                              </li>
                              <li class="sidebar-list">
                                  <router-link class="sidebar-link sidebar-title link-nav" to="/members/affiliates">
                                    <svg class="stroke-icon">
                                      <use href="@/assets/svg/icon-sprite.svg#stroke-user"></use>
                                    </svg>
                                    <svg class="fill-icon">
                                      <use href="@/assets/svg/icon-sprite.svg#fill-user"></use>
                                    </svg><span>Your affiliates</span>
                                  </router-link>
                              </li>
                              <li class="sidebar-list">
                                <router-link class="sidebar-link sidebar-title link-nav" to="/members/bonus">
                                  <svg class="stroke-icon">
                                    <use href="@/assets/svg/icon-sprite.svg#stroke-support-tickets"></use>
                                  </svg>
                                  <svg class="fill-icon">
                                    <use href="@/assets/svg/icon-sprite.svg#fill-support-tickets"></use>
                                  </svg><span>Bonus</span>
                                </router-link>
                            </li>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="simplebar-placeholder" style="width: auto; height: 2133px;"></div>
                    </div>
                    <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                      <div class="simplebar-scrollbar simplebar-visible" style="width: 0px; display: none;"></div>
                    </div>
                    <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                      <div class="simplebar-scrollbar simplebar-visible" style="height: 25px; transform: translate3d(0px, 0px, 0px); display: block;"></div>
                    </div>
                  </ul>
                </div>

                <!-- <div class="right-arrow" id="right-arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right">
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                </div> -->

              </nav>
            </div>
          </div>

          <div class="page-body">

            <router-view></router-view>

          </div>


      </div>

    
  


    </div>

      <div id="app" v-else>
      
        <router-view/>
      
      </div>

</div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import clickOutside from '@/directives/clickOutside';
export default {
  data() {
    return {
        isProfileOpen: false,
        isSideBarOpen: false,
        mode: 'dark',
    };
  },
  computed: {
      ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
      isSpecificRoute() {
          const specificRoutes = [
            '/members/mining', 
            '/members/deposit', 
            '/members/withdraw', 
            '/members/affiliates', 
            '/members/account',
            '/members/settings',
            '/members/bonus'
          ];
          return specificRoutes.includes(this.$route.path);
      }
  },
  mounted() {
    //this.loadMode()
    if (this.autenticado) {
      this.listenForDeposits();
    }
  },
  directives: {
    clickOutside,
  },
  methods: {
    listenForDeposits() {
      window.Echo.private(`deposits.${this.user.user.id}`)
        .listen('DepositReceived', (data) => {
      //    this.deposits.push(data);
          this.notifyUser(data);
        });
    },
    notifyUser(data) {
      // Aquí puedes implementar la lógica para notificar al usuario,
      // como mostrar un mensaje en pantalla.
      this.$swal({
        toast: true,
        icon: "success",
        title: `Deposit received: ${data.amountUSD.toFixed(2)} USD, Power Updated: ${data.hashRate} GH/s`,
        showConfirmButton: false,
        timer: 3500
      });
    },
    checkWindowSize() {
      const width = window.innerWidth;
      if (width <= 1184) {
        this.isCloseIcon = true;
      } else {
        this.isCloseIcon = false;
      }
    },
    toggleSideBar() {
      this.isSideBarOpen = !this.isSideBarOpen
    },
    hideSideBar() {
      this.isSideBarOpen = false;
    },
    toggleMode(){
      const element = document.body;
      if (this.mode === 'light') {
        this.mode = 'dark';
        element.classList.add('dark-only');
        element.classList.remove('light');
      } else {
        this.mode = 'light';
        element.classList.add('light');
        element.classList.remove('dark-only');
      }
      localStorage.setItem('mode', this.mode);
      //this.loadMode()

    },
    toggleProfile() {
        this.isProfileOpen = !this.isProfileOpen;
    },
    hide() {
        this.isProfileOpen = false;
    },
    ...mapActions({
        logout: 'user/logout'
    }),
    onLogOut() {
        this.logout().then((res) => {
            this.$router.push({ name: "login" });
        })
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

.header-center ul li a.router-link-exact-active {
  color: rgb(92, 14, 14);
}
.img-fluid {
    max-width: 85%;
    height: auto;
}
</style>
