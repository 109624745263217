<template>
    <div>

        <div class="jsx-3751484287 Chart">

            <div class="jsx-3751484287 swiper-container swiper-container-initialized swiper-container-horizontal">


      <div class="jsx-1406879810 trx-market">
        <h3 class="jsx-1406879810">TRX Market Performance</h3>
        <p class="jsx-1406879810 desc">Listed on over 130 exchanges, TRX, one of the most promising cryptos, connects millions of value investors across the globe.</p>

        <div class="jsx-1406879810 market-chart">
            <LineChartGenerator
        v-if="loaded"
        :options="chartOptions"
        :data="chartData"
      />
        </div>

        <div class="jsx-1406879810 numbers-content-bottom">
            <div class="jsx-1406879810 item">
                <p class="jsx-1406879810">Current Price</p>
                <p class="jsx-1406879810">$ {{ currentPrice !== null ? currentPrice.toFixed(6) : 'Loading...' }}</p>
            </div>
            <div class="jsx-1406879810 item">
                <p class="jsx-1406879810">Transaction Volume (24h)</p>
                <p class="jsx-1406879810">$ {{ transactionVolume !== null ? transactionVolume.toFixed(2) : 'Loading...' }}</p>
            </div>
            <div class="jsx-1406879810 item">
                <p class="jsx-1406879810">Transaction Count (24h)</p>
                <p class="jsx-1406879810">{{ transactionCount !== null ? transactionCount : 'Loading...' }}</p>
            </div>
        </div>

      </div>
    </div>

</div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { Line as LineChartGenerator } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    TimeScale
  } from 'chart.js';
  import 'chartjs-adapter-date-fns'; // Import the date adapter
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    TimeScale // Register the TimeScale for time axis
  );
  
  export default {
    name: 'TronPriceChart',
    components: {
      LineChartGenerator
    },
    data() {
      return {
        loaded: false,
        currentPrice: null,
        transactionVolume: null,
        transactionCount: null,
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'TRON Price (USD)',
              backgroundColor: '#C63127',
              borderColor: '#C63127',
              data: []
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day'
              },
              title: {
                display: true,
                text: 'Date'
              }
            },
            y: {
              title: {
                display: true,
                text: 'Price (USD)'
              }
            }
          }
        }
      };
    },
    mounted() {
      this.fetchPriceData();
      this.fetchMarketData();

    },
    methods: {
      fetchPriceData() {
        this.loaded = false;
        const apiUrl = 'https://api.coingecko.com/api/v3/coins/tron/market_chart?vs_currency=usd&days=1';
  
        axios.get(apiUrl).then(response => {
          const prices = response.data.prices;
  
          this.chartData.labels = prices.map(price => new Date(price[0]));
          this.chartData.datasets[0].data = prices.map(price => price[1]);
          this.loaded = true;
        }).catch(error => {
          console.error('Error fetching TRON price data:', error);
        });
      },
      fetchMarketData() {
        const apiUrl = 'https://api.coingecko.com/api/v3/coins/tron';

        axios.get(apiUrl).then(response => {
            this.currentPrice = response.data.market_data.current_price.usd;
            this.transactionVolume = response.data.market_data.total_volume.usd;
            this.transactionCount = response.data.market_data.total_supply;
        }).catch(error => {
            console.error('Error fetching TRON market data:', error);
        });
    }
    }
  };
  </script>
  
  <style scoped>
  canvas {
    width: 100%;
    height: 400px;
  }

  </style>
  