<template>
  <div>
    <div :style="isNavbarOpen ? 'display: block;' : 'display: none;'" class="jsx-41106609 mask"></div>
    
    <div class="jsx-3238525838 jsx-3195747767">
        <div class="jsx-3238525838 jsx-3195747767 mobnav blackStyle">
            <div class="jsx-3238525838 jsx-3195747767 mobilenav" :class="{ 'mobileshow': isNavbarOpen }">
            <router-link class="jsx-3238525838 jsx-3195747767 newIcon" to="/"></router-link>
                <span class="jsx-3238525838 jsx-3195747767 shownav newNav" @click="toggleNavbar"></span>
            </div>
        </div>
        <div class="jsx-3238525838 jsx-3195747767 menu-default" :class="{'navshow': isNavbarOpen }">
            <nav class="jsx-3238525838 jsx-3195747767" @click="toggleNavbar">
                <img src="@/assets/images/close.svg" class="jsx-3238525838 jsx-3195747767 closeBtn">
            </nav>
            <div class="jsx-3238525838 jsx-3195747767 spreads">
            <div id="urllist" class="jsx-3238525838 jsx-3195747767">
                <div class="jsx-905181412">
                <ul class="ant-menu ant-menu-dark ant-menu-root ant-menu-inline" role="menu">
                    <li class="ant-menu-item" role="menuitem" style="padding-left: 24px;">
                        <router-link class="jsx-905181412" to="/">Home</router-link>
                    </li>
                    <li class="ant-menu-item" role="menuitem" style="padding-left: 24px;">
                        <router-link class="jsx-905181412" to="/faqs">FAQ</router-link>
                    </li>
                    <li class="ant-menu-item" role="menuitem" style="padding-left: 24px;">
                        <router-link class="jsx-905181412" to="/partners">Affiliate</router-link>
                    </li>
                    <li class="ant-menu-item" role="menuitem" style="padding-left: 24px;">
                        <router-link class="jsx-905181412" to="/about">About</router-link>
                    </li>
                    <li class="ant-menu-item" role="menuitem" style="padding-left: 24px;">
                        <router-link class="jsx-905181412" to="/contact">Contact</router-link>
                    </li>
                    <template v-if="!autenticado">
                        <li class="ant-menu-item" role="menuitem" style="padding-left: 24px;">
                            <router-link class="jsx-905181412" to="/auth/login">Login</router-link>
                        </li>
                        <li class="ant-menu-item" role="menuitem" style="padding-left: 24px;">
                            <router-link class="jsx-905181412" to="/auth/register">Sign Up</router-link>
                        </li>
                    </template>
                    <template v-else>
                        <li class="ant-menu-item" role="menuitem" style="padding-left: 24px;">
                            <router-link class="jsx-905181412" to="/members/mining">Mining</router-link>
                        </li>
                        <li class="ant-menu-item" role="menuitem" style="padding-left: 24px;">
                            <a href="javascript:void(0)" class="btn btn-light" @click="onLogOut">Log Out</a>
                        </li>
                    </template>
                </ul>
                </div>
            </div>
            </div>
        </div>
        <div class="jsx-3238525838 jsx-3195747767 section-header section-header-2">
            <div class="jsx-3238525838 jsx-3195747767 header-center">
            <ul class="jsx-3238525838 jsx-3195747767">
                <router-link class="jsx-3238525838 jsx-3195747767 header-logo" to="/">
                <img alt="TRON" src="@/assets/images/logo-red.svg" class="jsx-3238525838 jsx-3195747767">
                
                    <TrxPrice key="trx-price"/>
                
                </router-link>
                <li class="jsx-3238525838 jsx-3195747767">
                <router-link class="jsx-3238525838 jsx-3195747767" 
                active-class="active"
                exact-active-class="exact-active" to="/">
                    <span class="jsx-3238525838 jsx-3195747767 text-active-box" style="color: rgb(255, 255, 255); text-decoration: none;">
                    <span class="jsx-3238525838 jsx-3195747767 active-text-item">Home</span>
                    </span>
                </router-link>
                </li>
                <li class="jsx-3238525838 jsx-3195747767">
                <router-link class="jsx-3238525838 jsx-3195747767" active-class="active"
                exact-active-class="exact-active" to="/faqs">
                    <span class="jsx-3238525838 jsx-3195747767 text-active-box" style="color: rgb(255, 255, 255); text-decoration: none;">
                    <span class="jsx-3238525838 jsx-3195747767 active-text-item">FAQ</span>
                    </span>
                </router-link>
                </li>
                <li class="jsx-3238525838 jsx-3195747767">
                <router-link class="jsx-3238525838 jsx-3195747767" active-class="active"
                exact-active-class="exact-active" to="/partners">
                    <span class="jsx-3238525838 jsx-3195747767 text-active-box" style="color: rgb(255, 255, 255); text-decoration: none;">
                    <span class="jsx-3238525838 jsx-3195747767 active-text-item">Affiliate</span>
                    </span>
                </router-link>
                </li>
                <li class="jsx-3238525838 jsx-3195747767">
                <router-link class="jsx-3238525838 jsx-3195747767" active-class="active"
                exact-active-class="exact-active" to="/about">
                    <span class="jsx-3238525838 jsx-3195747767 text-active-box" style="color: rgb(255, 255, 255); text-decoration: none;">
                    <span class="jsx-3238525838 jsx-3195747767 active-text-item">About</span>
                    </span>
                </router-link>
                </li>
                <li class="jsx-3238525838 jsx-3195747767">
                <router-link class="jsx-3238525838 jsx-3195747767" active-class="active"
                exact-active-class="exact-active" to="/contact">
                    <span class="jsx-3238525838 jsx-3195747767 text-active-box" style="color: rgb(255, 255, 255); text-decoration: none;">
                    <span class="jsx-3238525838 jsx-3195747767 active-text-item">Contact</span>
                    </span>
                </router-link>
                </li>
                <template v-if="!autenticado">
                <li class="jsx-3238525838 jsx-3195747767">
                    <router-link to="/auth/login" active-class="active"
                exact-active-class="exact-active" class="jsx-3238525838 jsx-3195747767" >
                    <span class="jsx-3238525838 jsx-3195747767 text-active-box" style="color: rgb(255, 255, 255); text-decoration: none;">
                        <span class="jsx-3238525838 jsx-3195747767 active-text-item">Login</span>
                    </span>
                    </router-link>
                    
                </li>
                <li class="jsx-3238525838 jsx-3195747767">
                    <router-link to="/auth/register" active-class="active"
                exact-active-class="exact-active" class="jsx-3238525838 jsx-3195747767" >
                        <span class="jsx-3238525838 jsx-3195747767 text-active-box" style="color: rgb(255, 255, 255); text-decoration: none;">
                            <span class="jsx-3238525838 jsx-3195747767 active-text-item">Sign Up</span>
                        </span>
                        

                    </router-link>
                </li>
            </template>
            <template v-else>
                <li class="jsx-3238525838 jsx-3195747767">
                    <router-link to="/members/mining" active-class="active"
                exact-active-class="exact-active" class="jsx-3238525838 jsx-3195747767" >
                    Mining</router-link>
                </li>
                <li class="jsx-3238525838 jsx-3195747767">
                <a href="javascript:void(0)" @click="onLogOut"  class="jsx-3238525838 jsx-3195747767" >
                        <span class="jsx-3238525838 jsx-3195747767 text-active-box" style="color: rgb(255, 255, 255); text-decoration: none;">
                            <span class="jsx-3238525838 jsx-3195747767 active-text-item">Log Out</span>
                        </span>
                    </a>
                </li>
            </template>
            </ul>
            </div>
        </div>
        </div>


  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import clickOutside from '../directives/clickOutside';
import TrxPrice from '@/components/TrxPrice.vue'
import axios from 'axios'
export default {
  name: 'TronmineHeader',
    components:{ TrxPrice },
  data() {
    return {
        isNavbarOpen: false,
    };
  },
  computed: {
      isSpecificRoute() {
          const specificRoutes = ['/', '/faqs', '/partners', '/about','/contact', '/auth/login','/auth/register'];
          return specificRoutes.includes(this.$route.path);
      },
      ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
  },
  mounted() {
    
  },
  beforeMount() {
    this.interval = setInterval(this.fetchTrxPrice, 60000);
  },
  methods: {
    ...mapActions({
        logout: 'user/logout'
    }),
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    onLogOut() {
        this.logout().then((res) => {
            this.$router.push({ name: "login" });
        })
    },
    
  },
};
</script>
<style scoped>
.header-logo {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra el contenido horizontalmente */
    text-align: center; /* Centra el texto horizontalmente */
}

.header-logo .logo {
    display: block;
    margin-bottom: 10px; /* Añade un espacio entre el logo y el precio */
}

.active {
  color: red; /* O cualquier estilo que quieras para la ruta activa */
}

.exact-active {
  font-weight: bold; /* O cualquier estilo que quieras para la ruta exactamente activa */
  color: rgb(92, 14, 14);
}

.active-text-item {
  /* Tus estilos personalizados para el texto activo */
}
</style>